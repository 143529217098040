import './FederatedLoginButton.scss';

import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../context/User';
import { AppleLogo } from './components/AppleLogo';
import { GoogleLogo } from './components/GoogleLogo';

interface Props {
  afterAuthPath?: string;
  variation?: 'default' | 'simple';
}

const className = 'c-FederatedLoginButton';

const FederatedLoginButton = ({
  afterAuthPath,
  variation = 'default',
}: Props) => {
  const { t } = useTranslation();
  const { federatedSignIn } = useUser();

  return (
    <div className={className}>
      {variation === 'default' && (
        <div className={`${className}__divider`}>{t('login.or', 'or')}</div>
      )}
      <div className={`${className}__buttons`}>
        <button
          type="button"
          className={`${className}__login-button ${className}__login-button--google`}
          onClick={() =>
            federatedSignIn(
              CognitoHostedUIIdentityProvider.Google,
              afterAuthPath,
            )
          }
        >
          <GoogleLogo />
          {variation === 'default' && 'Google'}
        </button>
        <button
          type="button"
          className={`${className}__login-button ${className}__login-button--apple`}
          onClick={() =>
            federatedSignIn(
              CognitoHostedUIIdentityProvider.Apple,
              afterAuthPath,
            )
          }
        >
          <AppleLogo />
          {variation === 'default' && 'Apple'}
        </button>
      </div>
    </div>
  );
};

export default FederatedLoginButton;
