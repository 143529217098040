import './SignUpForm.scss';

import {
  Button,
  Checkbox,
  Input,
  Spinner,
  Toast,
  useConfig,
  useGA4,
} from '@travelwin/core';
import classNames from 'classnames';
import { Formik, FormikProps } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ANALYTICS_EVENT_TYPES } from '../../analytics';
import { useUser } from '../../context/User';
import SentryEventReport from '../../monitoring/SentryEventReport/SentryEventReport';
import useErrorWatcher from '../../monitoring/useErrorWatcher';
import { getUserValidator } from '../../validation/userValidator';
import FederatedLoginButton from '../FederatedLoginButton/FederatedLoginButton';
import { TermsDisclaimer } from '../TermsDisclaimer';

interface Props {
  afterAuthPath?: string;
  callback: () => void;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  guestCheckoutEmail?: string;
}

interface Values {
  email: string;
  password: string;
  confirmPassword: string;
  tel: string;
  promo_optin: boolean;
}

const initialValues: Values = {
  email: '',
  password: '',
  confirmPassword: '',
  tel: '',
  promo_optin: false,
};

const className = 'c-SignUpForm';

type SignUpError = { code: string } | null;

const SignUpForm = ({
  afterAuthPath,
  callback,
  setEmail,
  setPassword,
  guestCheckoutEmail,
}: Props) => {
  const [awsError, setAwsError] = useState<SignUpError>(null);
  const [isLoading, setIsLoading] = useState(false);
  const sentryEventInfo = useErrorWatcher(awsError);

  const { registerUser } = useUser();
  const { t } = useTranslation();
  const { trackEvent } = useGA4();
  const { COGNITO_OAUTH_DOMAIN, MARKETING_ENABLED } = useConfig();

  const userValidator = getUserValidator(t);
  const userSchema = yup.object().shape({
    email: userValidator.email,
    password: userValidator.password,
  });

  const hasError = (props: FormikProps<Values>, key: keyof Values) => {
    return !!(props.errors[key] && props.touched[key]);
  };

  const showError = (props: FormikProps<Values>, key: keyof Values) => {
    return hasError(props, key) && <Toast error> {props.errors[key]}</Toast>;
  };

  const showAwsError = () => {
    if (!awsError) {
      return null;
    }

    let message: string;
    switch (awsError.code) {
      case 'UsernameExistsException':
        message = t(
          'signup.error.emailExists',
          'An account with the given email already exists',
        );
        break;
      case 'InvalidPasswordException':
        message = t(
          'signup.error.invalidPassword',
          'Password did not conform with policy',
        );
        break;
      default:
        message = t(
          'error.generic',
          'An error has occurred, please try again later',
        );
    }
    return (
      <>
        <Toast error>{message}</Toast>
        <SentryEventReport eventInfo={sentryEventInfo} />
      </>
    );
  };

  const signUp = (values: Values) => {
    const email = values.email.toLowerCase().trim();
    setEmail(email);
    setPassword(values.password);
    setIsLoading(true);

    registerUser(email, values.password, true, values.promo_optin)
      .then(() => {
        setAwsError(null);
        callback();
      })
      .catch((err: SignUpError) => {
        setAwsError(err);
        trackEvent(ANALYTICS_EVENT_TYPES.SignUpError);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const showSpinner = () => isLoading && <Spinner size={12} color="white" />;

  const showSubmitButton = (props: FormikProps<Values>) => {
    return (
      <Button
        data-testid="button-signup-continue"
        disabled={
          Object.keys(props.errors).length > 0 ||
          (!props.dirty && !props.isSubmitting) ||
          isLoading
        }
        fluid
        type="submit"
      >
        {t('form.create_account_title', 'Create an account')} {showSpinner()}
      </Button>
    );
  };

  return (
    <div className={className}>
      <Formik
        initialValues={{
          ...initialValues,
          email: guestCheckoutEmail || initialValues.email,
        }}
        validationSchema={userSchema}
        onSubmit={signUp}
      >
        {(props) => (
          <form
            className={classNames(
              `${className}__form`,
              guestCheckoutEmail && 'guestCheckout',
            )}
            onSubmit={props.handleSubmit}
          >
            <h2
              className={`${className}__title`}
              data-testid="h2-create-account"
            >
              {guestCheckoutEmail
                ? t(
                    'form.create_account_title_optional',
                    'Create an account (optional)',
                  )
                : t('form.create_account_title', 'Create an account')}
            </h2>
            <p className={`${className}__text`}>
              {t(
                'form.create_account_text',
                'Create an account to manage your plans, top up your plan, and get deals.',
              )}
            </p>
            <div className={`${className}__field`}>
              <Input
                name="email"
                data-testid="input-signup-email"
                label={t('my_account.form_email_label', 'Email')}
                autoComplete="email"
                placeholder={t('my_account.form_email_label', 'Email')}
                error={hasError(props, 'email')}
                onChange={props.handleChange}
                onBlur={() => {
                  props.setFieldTouched(
                    'email',
                    !!props.touched.email || !!props.values.email,
                  );
                }}
                value={guestCheckoutEmail ?? props.values.email}
                icon={'envelope'}
                readOnly={Boolean(guestCheckoutEmail)}
              />
            </div>
            {showError(props, 'email')}
            <div className={`${className}__field`}>
              <Input
                name="password"
                data-testid="input-signup-password"
                label={t('form.create_password_label', 'Create Password')}
                type="password"
                autoComplete='new-password"'
                placeholder={t('form.create_password_label', 'Create Password')}
                error={hasError(props, 'password')}
                onChange={props.handleChange}
                onBlur={() => {
                  props.setFieldTouched(
                    'password',
                    !!props.touched.password || !!props.values.password,
                  );
                }}
                value={props.values.password}
                addEyeIcon
                icon={'lock'}
              />
            </div>
            {showError(props, 'password')}
            <div className={`${className}__password-reqs`}>
              <p className={`${className}__password-reqs-text`}>
                {t('signup.password_reqs_text', 'Your password must contain:')}
              </p>
              <ul className={`${className}__password-reqs-list`}>
                <li>
                  {t(
                    'signup.password_reqs_text_length',
                    'at least 8 characters',
                  )}
                </li>
                <li>
                  {t(
                    'signup.password_reqs_text_uppercase',
                    'an uppercase & lowercase character',
                  )}
                </li>
                <li>{t('signup.password_reqs_text_numer', 'a number')}</li>
              </ul>
            </div>
            {MARKETING_ENABLED && (
              <div className={`${className}__promo-optin-checkbox`}>
                <Checkbox
                  data-testid="checkbox-promo-optin"
                  name="promo_optin"
                  value="promo_optin"
                  onChange={props.handleChange}
                  checked={props.values.promo_optin}
                >
                  {t(
                    'form.promo_optin_line1',
                    `Email me with special offers and news.`,
                  )}
                </Checkbox>
                <div className={`${className}__promo-line-2`}>
                  {t(
                    'form.promo_optin_line2',
                    `(We do not share your data with third parties)`,
                  )}
                </div>
              </div>
            )}
            {showAwsError()}
            <div className={`${className}__submit`}>
              {showSubmitButton(props)}
              {!guestCheckoutEmail && COGNITO_OAUTH_DOMAIN && (
                <FederatedLoginButton afterAuthPath={afterAuthPath} />
              )}
              <TermsDisclaimer />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SignUpForm;
