import { captureException } from '@sentry/capacitor';
import { isFunction } from 'radash';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import MetaMobileAnalytics, {
  SignUpEventData,
  ACTION_VIA_SOURCE,
  useAnalytics,
} from '../../analytics';
import { useUser } from '../../context/User';
import { isHttpError } from '../../services/helpers/authorizedRequests';
import { registerContact } from '../../services/users/UsersService';
import { EmailVerification } from '../EmailVerification';
import SignUpForm from '../SignUpForm';
import { trackTikTokPixel } from '../TikTokPixel';

const [SIGN_UP, EMAIL_VERIFICATION] = [0, 1];

const className = 'c-Register';

interface Props {
  guestCheckoutEmail?: string;
  afterAuthPath?: string;
  handleCompleted: () => void;
  initiator?: ACTION_VIA_SOURCE.FREE_TRIAL;
}

const Register = ({
  afterAuthPath,
  guestCheckoutEmail,
  handleCompleted,
  initiator,
}: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(SIGN_UP);
  const [isHistoryPushed, setIsHistoryPushed] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const { signIn } = useUser();
  const stepRef = useRef(0);
  stepRef.current = step;

  const analyticsContext = useAnalytics();

  useEffect(() => {
    const popstate = () => {
      if (stepRef.current > SIGN_UP) {
        window.history.pushState(
          null,
          window.document.title,
          window.location.href,
        );
        setStep(stepRef.current - 1);
      }
    };

    window.addEventListener('popstate', popstate);

    return () => {
      window.removeEventListener('popstate', popstate);
    };
  }, []);

  useEffect(() => {
    if (!isHistoryPushed && step === EMAIL_VERIFICATION) {
      window.history.pushState(
        null,
        window.document.title,
        window.location.href,
      );
      setIsHistoryPushed(true);
    }
  }, [step]);

  const doRegisterContact = (marketingConsent: boolean) => {
    registerContact(email, marketingConsent).catch((e) => {
      const errorMessage = isHttpError(e)
        ? e.body.message
        : 'Registering contact failed';

      captureException(new Error(errorMessage), {
        extra: { email },
      });
    });
  };

  const loginUser = async () => {
    const trackingData: SignUpEventData = {
      method: 'Email',
    };

    if (initiator === ACTION_VIA_SOURCE.FREE_TRIAL) {
      analyticsContext.trackSignUpViaFreeTrial(trackingData);
    } else {
      analyticsContext.trackSignUp(trackingData);
    }

    MetaMobileAnalytics.sendSignUpEvent(trackingData);
    await trackTikTokPixel({
      event_id: uuidv4(),
      event_name: 'CompleteRegistration',
      email: email.toLowerCase(),
    });

    setIsSigningIn(true);
    const { marketingConsent } = await signIn(email, password);
    doRegisterContact(marketingConsent ?? false);
    setIsSigningIn(false);
    isFunction(handleCompleted) && handleCompleted();
  };

  const renderSignUpForm = () => {
    return (
      <SignUpForm
        callback={() => setStep(EMAIL_VERIFICATION)}
        setEmail={setEmail}
        setPassword={setPassword}
        afterAuthPath={afterAuthPath}
        guestCheckoutEmail={guestCheckoutEmail}
      />
    );
  };

  const renderEmailSentStep = () => {
    return (
      <EmailVerification
        isSigningIn={isSigningIn}
        email={email}
        onSuccess={loginUser}
      />
    );
  };

  const renderStep = () => {
    const stepScreens = [renderSignUpForm, renderEmailSentStep];
    const renderContent = stepScreens[step];
    return renderContent ? renderContent() : null;
  };

  return <div className={className}>{renderStep()}</div>;
};

export default Register;
