import './EmailVerification.scss';

import { EnvelopeOpen } from '@phosphor-icons/react';
import { Button, Toast, useGA4 } from '@travelwin/core';
import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ANALYTICS_EVENT_TYPES } from '../../analytics';
import { useUser } from '../../context/User';
import { captureError } from '../../monitoring/sentry';

const className = 'c-EmailVerification';

const CODE_LENGTH = 6;

type Props = {
  email: string;
  password?: string;
  isSigningIn?: boolean;
  onSuccess: () => void;
};

export const EmailVerification = ({
  email,
  password,
  onSuccess,
  isSigningIn,
}: Props) => {
  const { t } = useTranslation();
  const inputId = useId();

  const [code, setCode] = useState<string>('');
  const [hint, setHint] = useState<string | undefined>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { confirmRegistrationCode, resendRegistrationCode } = useUser();
  const { trackEvent } = useGA4();

  const verifyAccount = async () => {
    try {
      setIsLoading(true);
      setHint(undefined);
      setIsError(false);

      await confirmRegistrationCode(email, code, password);

      trackEvent(ANALYTICS_EVENT_TYPES.SignUpCodeValid);

      onSuccess();
    } catch (err) {
      trackEvent(ANALYTICS_EVENT_TYPES.SignUpCodeError);
      setHint(
        t(
          'form.incorrect_code_entered_error',
          'Your verification code was incorrect, please check again.',
        ),
      );
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={className}>
      <div className={`${className}__title`}>
        {t('verification.check_inbox_title', 'Check Your Email')}
      </div>
      <div className={`${className}__icon`}>
        <EnvelopeOpen size={64} weight="duotone" color="currentColor" />
      </div>
      <div className={`${className}__email-sent-text`}>
        {t(
          'verification.email_sent_to_text',
          'We have sent an email with a verification code to',
        )}{' '}
        <span className={`${className}__hint`}>{email}</span>
      </div>
      <div className={`${className}__verification-instructions`}>
        {t(
          'verification.verification_instructions',
          'To complete your email verification, please enter the 6-digit code sent to your email address.',
        )}
      </div>
      <div className={`${className}__verification-code-input-container`}>
        <label
          htmlFor={inputId}
          className={`${className}__verification-code-title`}
        >
          {t('verification.enter_code', 'Enter Verification Code')}
        </label>
        <input
          id={inputId}
          data-testid="input-verification-code"
          value={code}
          onChange={(e) => {
            if (e.target.value.length <= CODE_LENGTH) setCode(e.target.value);
            if (e.target.value.length === 0) setHint(undefined);
          }}
          placeholder={t(
            'form.enter_verification_code_label',
            'Enter Verification Code',
          )}
          className={`${className}__input`}
        />
      </div>
      <div className={`${className}__find-email-suggestion`}>
        {t(
          'verification.find_email_suggestion',
          "If you can't find it, check your spam folder or click the link below to resend the email.",
        )}
      </div>
      {hint && <Toast error={isError}>{hint}</Toast>}
      <Button
        data-testid="button-verify"
        onClick={verifyAccount}
        isLoading={isLoading || isSigningIn}
        disabled={isLoading || isSigningIn || code.length !== CODE_LENGTH}
        fluid
      >
        {t('verification.verify_label', 'Verify')}
      </Button>
      <Button
        data-testid="button-resend-code"
        fluid
        variant="secondary"
        disabled={isLoading}
        onClick={async () => {
          setIsError(false);
          setHint(undefined);
          await resendRegistrationCode(email)
            .then(() => {
              setHint(
                t(
                  'verification.code_resent_success_msg',
                  'Your verification code has been resent.',
                ),
              );
            })
            .catch((e) => {
              captureError(e);
              setIsError(true);
              setHint(
                t(
                  'verification.code_resent_error_msg',
                  'Something went wrong sending your code, please try again.',
                ),
              );
            });
        }}
      >
        {t('verification.resend_code_label', 'Resend Code')}
      </Button>
    </div>
  );
};
