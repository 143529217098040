export const AppleLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={24}
      height={24}
    >
      <path
        d="M11.782 5.53846C12.8359 5.53846 14.157 4.8061 14.9437 3.82962C15.6562 2.94469 16.1758 1.70884 16.1758 0.472981C16.1758 0.305149 16.1609 0.137317 16.1312 0C14.9586 0.0457724 13.5484 0.808645 12.7023 1.8309C12.0344 2.60903 11.4258 3.82963 11.4258 5.08074C11.4258 5.26383 11.4555 5.44692 11.4703 5.50795C11.5445 5.5232 11.6633 5.53846 11.782 5.53846ZM8.07109 24C9.51094 24 10.1492 23.0083 11.9453 23.0083C13.7711 23.0083 14.1719 23.9695 15.775 23.9695C17.3484 23.9695 18.4023 22.4743 19.3969 21.0095C20.5102 19.3312 20.9703 17.6834 21 17.6071C20.8961 17.5766 17.8828 16.3102 17.8828 12.7552C17.8828 9.67324 20.2578 8.28481 20.3914 8.178C18.818 5.85887 16.4281 5.79784 15.775 5.79784C14.0086 5.79784 12.5688 6.89638 11.6633 6.89638C10.6836 6.89638 9.39219 5.85887 7.86328 5.85887C4.95391 5.85887 2 8.33058 2 12.9994C2 15.8983 3.09844 18.965 4.44922 20.9485C5.60703 22.6268 6.61641 24 8.07109 24Z"
        fill="#fff"
      />
    </svg>
  );
};
